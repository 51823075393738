import React from "react"
import types from "prop-types"
import { formAccessExplanation } from "./utils"

const AccessExplanation = ({ categoryGrant, formGrant, formSubmissionGrant }) => {
  const explanation = formAccessExplanation({ categoryGrant, formGrant, formSubmissionGrant })
  if (!explanation) { return null }

  return (
    <span className="grow text-sm text-dark">{ explanation }</span>
  )
}

AccessExplanation.defaultProps = {
  categoryGrant: null,
  formGrant: null,
  formSubmissionGrant: null,
}

/* eslint-disable react/forbid-prop-types */
AccessExplanation.propTypes = {
  categoryGrant: types.object,
  formGrant: types.object,
  formSubmissionGrant: types.object,
}
/* eslint-enable react/forbid-prop-types */

export default AccessExplanation
